.prompt{
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: inherit;
  }

.spinner{
  position: fixed;
  display: flex;
  /* top: 50%; */
  left: 37.5%;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  animation: spin 3s infinite linear;
}

.center-container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  z-index: 1; 
}

@keyframes spin {
  0% {
    transform: rotate(0deg);;
  }
  100% {
    transform: rotate(360deg);
  }
}