
.grid-container{
    position: absolute;
    top: 100px; /* Adjust the top position to accommodate the navbar height */
    left: 0; /* Align the grid container to the left edge */
    /* height: calc(100vh - 60px);  */
    /* right: 0;
    bottom: 0; */
    width: 100%; /* Make the grid container span the full width */
    
    /* background-color: #aaaaaa; */
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 1;
    overflow-y: scroll;
    /* overflow-x: hidden;       */
    padding-top: 30px;  
    padding-bottom: 30px;         
    height: max-content;
    
}
  
.album-cover-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr); 
    gap: 30px;
    max-width: 900px;
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
    /* overflow-y: scroll; */
    overflow-x: hidden;
    
}

.album-hover{
    width: 136px;
    height: 75px;
    color: white;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    visibility: hidden;
    transform: translate(6%, -250%);
    /* overflow: hidden; */
    opacity: 0;
    transition: opacity 0.3s ease;
}

.album-hover.visible {
    opacity: 1;
    visibility: visible;
}

.album-cover{
    /* width: 100%;
    height: 100%; */
    width: 150px;
    height: 150px;
    border-radius: 5%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    border: .75px solid black;
}

.album-cover.hovered{
    filter: brightness(0.5);
}

.album-container{
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 152px;
    height: 152px;
    
  }
  
  .grid-button{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }
  .grid-button:hover{
    filter: brightness(1.2);
  }
  
  .add-container{
    position: relative;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    left: 0%;
    z-index: 3;
    transform: translate(20%,-100%);
  }

  .play-container{
    position: relative;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    left: 75%;
    z-index: 3;
    transform: translateY(-203%);
  }
  

@media (max-width: 900px) {
    .album-cover-grid {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }
  }

@media (max-width: 800px){
    .grid-container{
        top: 200px;
        /* height: calc(100vh - 180px);  */
    }
    .album-cover-grid{
        top: 120px;
    }
}
  
@media (max-width: 730px) {
    .album-cover-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
    }
}
 
@media (max-width: 550px) {
    .album-container{
        /* top: 180px; */
        width: 200px;
        height: 200px;
        
    }
    .album-cover-grid {
        /* top:180px; */
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(20, 1fr);
    /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
    }

    .album-cover {
        width: 198px;
        height: 198px;
    }

    .album-hover{
        transform: translate(24%, -285%);
    }

    .play-container{
        left: 81%;
    }

}

@media (max-width: 500px){
    .grid-container{
        top: 220px;
    }

}

