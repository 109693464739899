html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 100px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #dadce1;
  color: var(--text-color);
  text-decoration: none;
}

p {
  margin-bottom: 0;
  text-align: center;
}















.grid-container{
    position: absolute;
    top: 100px; /* Adjust the top position to accommodate the navbar height */
    left: 0; /* Align the grid container to the left edge */
    /* height: calc(100vh - 60px);  */
    /* right: 0;
    bottom: 0; */
    width: 100%; /* Make the grid container span the full width */
    
    /* background-color: #aaaaaa; */
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 1;
    overflow-y: scroll;
    /* overflow-x: hidden;       */
    padding-top: 30px;  
    padding-bottom: 30px;         
    height: -webkit-max-content;         
    height: max-content;
    
}
  
.album-cover-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr); 
    grid-gap: 30px; 
    gap: 30px;
    max-width: 900px;
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
    /* overflow-y: scroll; */
    overflow-x: hidden;
    
}

.album-hover{
    width: 136px;
    height: 75px;
    color: white;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    visibility: hidden;
    -webkit-transform: translate(6%, -250%);
            transform: translate(6%, -250%);
    /* overflow: hidden; */
    opacity: 0;
    transition: opacity 0.3s ease;
}

.album-hover.visible {
    opacity: 1;
    visibility: visible;
}

.album-cover{
    /* width: 100%;
    height: 100%; */
    width: 150px;
    height: 150px;
    border-radius: 5%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    border: .75px solid black;
}

.album-cover.hovered{
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

.album-container{
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 152px;
    height: 152px;
    
  }
  
  .grid-button{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }
  .grid-button:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
  
  .add-container{
    position: relative;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    left: 0%;
    z-index: 3;
    -webkit-transform: translate(20%,-100%);
            transform: translate(20%,-100%);
  }

  .play-container{
    position: relative;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    left: 75%;
    z-index: 3;
    -webkit-transform: translateY(-203%);
            transform: translateY(-203%);
  }
  

@media (max-width: 900px) {
    .album-cover-grid {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }
  }

@media (max-width: 800px){
    .grid-container{
        top: 200px;
        /* height: calc(100vh - 180px);  */
    }
    .album-cover-grid{
        top: 120px;
    }
}
  
@media (max-width: 730px) {
    .album-cover-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
    }
}
 
@media (max-width: 550px) {
    .album-container{
        /* top: 180px; */
        width: 200px;
        height: 200px;
        
    }
    .album-cover-grid {
        /* top:180px; */
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(20, 1fr);
    /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
    }

    .album-cover {
        width: 198px;
        height: 198px;
    }

    .album-hover{
        -webkit-transform: translate(24%, -285%);
                transform: translate(24%, -285%);
    }

    .play-container{
        left: 81%;
    }

}

@media (max-width: 500px){
    .grid-container{
        top: 220px;
    }

}


.popup{
    position: fixed;
    display: flex;
    width: 200px;
    height: 150px;
    z-index: 9998;
    background-color: #595b5d;
    align-items: center;
    justify-content: center;
    border-radius: 12.5px;
    color: #ffffff;
    text-align: center;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 1rem;
}

.popup-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.alert-container{
    position: fixed;
    top: 60px; /* Adjust the top position to accommodate the navbar height */
    left: 0; /* Align the grid container to the left edge */
    width: 100vw; /* Make the grid container span the full width */
    height: calc(100vh - 60px); 
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 9000;
    /* overflow-y: scroll;
    overflow-x: hidden;      
    padding-top: 30px;  
    padding-bottom: 30px;         
    height: max-content; */
    
}

.account-container{
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 25%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

#popup-button{
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, -30%);
            transform: translate(-50%, -30%);
}

.navbar {
  position: fixed;
  width: 100%;
  height: var(--nav-size);
  background-color: var(--bg);
  -webkit-padding-start: 0 1rem;
          padding-inline-start: 0 1rem;
  border-bottom: var(--border);
  z-index: 500;
}

.navbar-nav{
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
}

.nav-item{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.title-box{
  height: 25px;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  
}
.title-box h1{
  font-family: 'Courier New', Courier, monospace;
  color: white;
}

.buttons-box{
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dropdown{
  position: fixed;
  top: 58px;
  width: 250px;
  /* width: 300px; */
  -webkit-transform: translate(40%, 8%);
          transform: translate(40%, 8%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  /* transition: height 0.3s ease; */
  z-index: 9998;
}

@media (max-width: 850px){
  .dropdown {
    width: 200px;
  }
}
@media (max-width: 800px){
  .navbar{
    z-index: 9997;
  }
}


.dropdown-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: 100%;
  float: left;
  margin-right: 20px;
  position: fixed;
  white-space: nowrap;
  height: var(--button-size);
  border-radius: 5%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: left;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
  z-index: 9999;
}

.dropdown-button:hover{
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.menu-item{
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: var(--speed);
  padding: 0.5rem
}

.menu-item:hover{
  background-color: #525357;
}

.icon-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  margin-right: 5px;
  white-space: nowrap;
  /* height: var(--button-size); */
  height: 32px;
  background-color: #484a4d;
  border-radius: 10%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  min-width: 50px;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
  color: var(--text-color);
  text-decoration: none;
  /* border: .5px solid black; */
}
  
.icon-button:hover{
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.login-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  margin-right: 5px;
  white-space: nowrap;
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 5%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  min-width: 50px;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.login-button:hover{
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}



.display-container{
  position: fixed;
  width: 520px;
  left: 32%;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 700;
  -webkit-transform: translate(28px, 35px);
          transform: translate(28px, 35px);
}

.display{
  /* max-width: 480px;
  min-width: 320px; */
  width: 425px;
  height: 4.5em;
  font-size: .75em;
  border-radius: 1em;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  /* background-color: #8f939a; */
  /* border: .5px solid black; */
  background-color: #82807f;
  z-index: 9997;
  
  /* z-index: 1; */
}

.display-left{
  align-items: center;
  justify-content: center;
  display: flex;
  width: 45%;
  -webkit-transform: translateX(-3px);
          transform: translateX(-3px);
}

.back-icon{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); 
}

.display-right{
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate(1%, -6%);
          transform: translate(1%, -6%);
  /* overflow-x: hidden; */
}

.display-center{
  display: flex;
  width: 15%;
  align-items: center;
  justify-content: center;
  
  /* transform: translate(12%, -6%); */
}

.playing-container{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 5%;
  background-color: #484a4d;
  /* border: .5px solid black; */
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.playing-item{
    width: 44px;
    height: 44px;
    border-radius: 5%;
    /* object-fit: cover; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    /* transform: translate(6%,6%); */
    border: .25px solid black;
    -webkit-filter:brightness(0.8);
            filter:brightness(0.8)
}

.player-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* float: left; */
  margin-right: 5px;
  white-space: nowrap;
  /* height: var(--button-size); */
  height: 35px;
  background-color: #484a4d;
  border-radius: 10%;
  padding: 1px;
  margin: 1px;
  display: flex;
  align-items: center;
  min-width: 50px;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
  /* border: .5px solid black; */
}


.player-button:hover{
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.screen{
  /* width: 90%; */
  width: 175px;
  height: 3em;
  font-size: .75em;
  white-space: nowrap;
  /* overflow-x: hidden;
  overflow-y: hidden; */
  overflow: hidden;
  border-radius: .8em;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  /* background-color: #c7ccd4; */
  background-color: #adaaa8;
  -webkit-transform: translateX(5%);
          transform: translateX(5%);
  border: .5px solid black;
}


.song{
  font-weight: bold;
  -webkit-animation: scroll 20s linear infinite;
          animation: scroll 20s linear infinite;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.progress{
  /* position: sticky; */
  height: 1em;
  width: 95%;
  bottom: 0em;
  align-items: center;
  justify-content: center;
  -webkit-transform: translateX(5%);
          transform: translateX(5%);
  }

@media (max-width: 800px){
  .display-container{
    top: var(--nav-size);
    width: 100%;
    left: 0%;
    /* height: calc(var(--nav-size)); */
    height: 75px;
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }

}

@media (max-width: 500px) {

  .display-container{
    top: var(--nav-size);
    width: 100%;
    left: 0%;
    /* height: calc(var(--nav-size)*2); */
    height: 120px;
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  .display{
    width: 360px;
    padding: auto;
    height: 90px;
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
    
  }
  .display-left{
    height: 40%;
    width: 50%;
    justify-content: right;
    -webkit-transform: translate(20.5%,-107%);
            transform: translate(20.5%,-107%);
  }
  .display-right{
    position: relative;
    height: 75%;
    right: 0;
    width: 70%;
    -webkit-transform: translate(12.5%,100%);
            transform: translate(12.5%,100%);
  }

  .display-center{
    display: flex;
    /* height: 90%;
    width: 30%; */
    height: 80px;
    width: 80px;
    align-items: center;
    justify-content: center;
    -webkit-transform: translate(-138px, -3px);
            transform: translate(-138px, -3px);
  }

  .playing-container{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 80px;
    height: 80px;
  }
  
  .playing-item{
      /* width: 90%;
      height: 90%; */
      width: 72px;
      height: 72px;
  }
  .progress{
    -webkit-transform: translate(5%, -35%);
            transform: translate(5%, -35%);
    bottom: 2em;
    width: 100%;
  }
  .screen{
    /* width: 260px; */
    width: 240px;
    -webkit-transform: translate(5%,-10%);
            transform: translate(5%,-10%);
  }

}

@media (max-width: 380px){
  .display{
    width: 315px;
  }

  .screen{
    width: 195px;
    -webkit-transform: translate(8.5%,-10%);
            transform: translate(8.5%,-10%);
  }

  .progress{
    -webkit-transform: translate(8.5%,-40%);
            transform: translate(8.5%,-40%);

  }

  .display-left{
    -webkit-transform: translate(31.5%,-105%);
            transform: translate(31.5%,-105%);
  }

  .display-center{
    -webkit-transform: translate(-130%, 5%);
            transform: translate(-130%, 5%);
  }
  .playing-container{
    -webkit-transform: translate(-3%, -9%);
            transform: translate(-3%, -9%);

  }

}
  
.prompt{
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: inherit;
  }

.spinner{
  position: fixed;
  display: flex;
  /* top: 50%; */
  left: 37.5%;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: spin 3s infinite linear;
          animation: spin 3s infinite linear;
}

.center-container{
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 300px;
  z-index: 1; 
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

