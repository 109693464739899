
.navbar {
  position: fixed;
  width: 100%;
  height: var(--nav-size);
  background-color: var(--bg);
  padding-inline-start: 0 1rem;
  border-bottom: var(--border);
  z-index: 500;
}

.navbar-nav{
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
}

.nav-item{
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.title-box{
  height: 25px;
  transform: translateY(-10px);
  
}
.title-box h1{
  font-family: 'Courier New', Courier, monospace;
  color: white;
}

.buttons-box{
  display: flex;
  width: fit-content;
}

.dropdown{
  position: fixed;
  top: 58px;
  width: 250px;
  /* width: 300px; */
  transform: translate(40%, 8%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  /* transition: height 0.3s ease; */
  z-index: 9998;
}

@media (max-width: 850px){
  .dropdown {
    width: 200px;
  }
}
@media (max-width: 800px){
  .navbar{
    z-index: 9997;
  }
}


.dropdown-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: 100%;
  float: left;
  margin-right: 20px;
  position: fixed;
  white-space: nowrap;
  height: var(--button-size);
  border-radius: 5%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: left;
  transition: filter 300ms;
  z-index: 9999;
}

.dropdown-button:hover{
  filter: brightness(1.2);
}

.menu-item{
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: var(--speed);
  padding: 0.5rem
}

.menu-item:hover{
  background-color: #525357;
}

.icon-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: fit-content;
  float: left;
  margin-right: 5px;
  white-space: nowrap;
  /* height: var(--button-size); */
  height: 32px;
  background-color: #484a4d;
  border-radius: 10%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  min-width: 50px;
  justify-content: center;
  transition: filter 300ms;
  color: var(--text-color);
  text-decoration: none;
  /* border: .5px solid black; */
}
  
.icon-button:hover{
  filter: brightness(1.2);
}

.login-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: fit-content;
  float: left;
  margin-right: 5px;
  white-space: nowrap;
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 5%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  min-width: 50px;
  justify-content: center;
  transition: filter 300ms;
}

.login-button:hover{
  filter: brightness(1.2);
}
