

.display-container{
  position: fixed;
  width: 520px;
  left: 32%;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 700;
  transform: translate(28px, 35px);
}

.display{
  /* max-width: 480px;
  min-width: 320px; */
  width: 425px;
  height: 4.5em;
  font-size: .75em;
  border-radius: 1em;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  /* background-color: #8f939a; */
  /* border: .5px solid black; */
  background-color: #82807f;
  z-index: 9997;
  
  /* z-index: 1; */
}

.display-left{
  align-items: center;
  justify-content: center;
  display: flex;
  width: 45%;
  transform: translateX(-3px);
}

.back-icon{
  transform: rotate(180deg); 
}

.display-right{
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(1%, -6%);
  /* overflow-x: hidden; */
}

.display-center{
  display: flex;
  width: 15%;
  align-items: center;
  justify-content: center;
  
  /* transform: translate(12%, -6%); */
}

.playing-container{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 5%;
  background-color: #484a4d;
  /* border: .5px solid black; */
  transform: translateX(5px);
}

.playing-item{
    width: 44px;
    height: 44px;
    border-radius: 5%;
    /* object-fit: cover; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    /* transform: translate(6%,6%); */
    border: .25px solid black;
    filter:brightness(0.8)
}

.player-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: fit-content;
  /* float: left; */
  margin-right: 5px;
  white-space: nowrap;
  /* height: var(--button-size); */
  height: 35px;
  background-color: #484a4d;
  border-radius: 10%;
  padding: 1px;
  margin: 1px;
  display: flex;
  align-items: center;
  min-width: 50px;
  justify-content: center;
  transition: filter 300ms;
  /* border: .5px solid black; */
}


.player-button:hover{
  filter: brightness(1.2);
}

.screen{
  /* width: 90%; */
  width: 175px;
  height: 3em;
  font-size: .75em;
  white-space: nowrap;
  /* overflow-x: hidden;
  overflow-y: hidden; */
  overflow: hidden;
  border-radius: .8em;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  /* background-color: #c7ccd4; */
  background-color: #adaaa8;
  transform: translateX(5%);
  border: .5px solid black;
}


.song{
  font-weight: bold;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.progress{
  /* position: sticky; */
  height: 1em;
  width: 95%;
  bottom: 0em;
  align-items: center;
  justify-content: center;
  transform: translateX(5%);
  }

@media (max-width: 800px){
  .display-container{
    top: var(--nav-size);
    width: 100%;
    left: 0%;
    /* height: calc(var(--nav-size)); */
    height: 75px;
    transform: translateY(10%);
  }

}

@media (max-width: 500px) {

  .display-container{
    top: var(--nav-size);
    width: 100%;
    left: 0%;
    /* height: calc(var(--nav-size)*2); */
    height: 120px;
    transform: translateY(-5%);
  }
  .display{
    width: 360px;
    padding: auto;
    height: 90px;
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
    
  }
  .display-left{
    height: 40%;
    width: 50%;
    justify-content: right;
    transform: translate(20.5%,-107%);
  }
  .display-right{
    position: relative;
    height: 75%;
    right: 0;
    width: 70%;
    transform: translate(12.5%,100%);
  }

  .display-center{
    display: flex;
    /* height: 90%;
    width: 30%; */
    height: 80px;
    width: 80px;
    align-items: center;
    justify-content: center;
    transform: translate(-138px, -3px);
  }

  .playing-container{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 80px;
    height: 80px;
  }
  
  .playing-item{
      /* width: 90%;
      height: 90%; */
      width: 72px;
      height: 72px;
  }
  .progress{
    transform: translate(5%, -35%);
    bottom: 2em;
    width: 100%;
  }
  .screen{
    /* width: 260px; */
    width: 240px;
    transform: translate(5%,-10%);
  }

}

@media (max-width: 380px){
  .display{
    width: 315px;
  }

  .screen{
    width: 195px;
    transform: translate(8.5%,-10%);
  }

  .progress{
    transform: translate(8.5%,-40%);

  }

  .display-left{
    transform: translate(31.5%,-105%);
  }

  .display-center{
    transform: translate(-130%, 5%);
  }
  .playing-container{
    transform: translate(-3%, -9%);

  }

}
  