.popup{
    position: fixed;
    display: flex;
    width: 200px;
    height: 150px;
    z-index: 9998;
    background-color: #595b5d;
    align-items: center;
    justify-content: center;
    border-radius: 12.5px;
    color: #ffffff;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
}

.popup-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.alert-container{
    position: fixed;
    top: 60px; /* Adjust the top position to accommodate the navbar height */
    left: 0; /* Align the grid container to the left edge */
    width: 100vw; /* Make the grid container span the full width */
    height: calc(100vh - 60px); 
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 9000;
    /* overflow-y: scroll;
    overflow-x: hidden;      
    padding-top: 30px;  
    padding-bottom: 30px;         
    height: max-content; */
    
}

.account-container{
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);
}

#popup-button{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -30%);
}